import {ChangeEvent, FC, useEffect, useState} from "react";
import {Box, FormControlLabel, Radio, RadioGroup, TextField, Typography} from "@material-ui/core";
import {CalcProps, PipeKey, PipeResultValues, pressureContainerValues} from "../../../types/types";
import {calcPipeValue, defaultPressureContainerValues, PipeCalcValue} from "./PipeDiameterHelper";
import PipeVolumeUnitSelect, {VolumeUnit} from "./PipeVolumeUnitSelect";
import CalcWrapper from "../../../controls/CalcWrapper";

interface PipeDiameterProps extends CalcProps{
}

const PipeDiameter: FC<PipeDiameterProps> = ({expanded}) => {
    const [selected, setSelected] = useState<PipeCalcValue>(PipeCalcValue.Diameter);
    const [pressureContainerValues, setPressureContainerValues] = useState<pressureContainerValues>(defaultPressureContainerValues);
    const [resultValues, setResultValues] = useState<PipeResultValues>({});
    const [volumeUnit, setVolumeUnit] = useState<VolumeUnit>(VolumeUnit.MeterMinute);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: PipeKey) => {
        const {value} = event.target;
        let newValues = {...pressureContainerValues, [key]: value};
        const volume = volumeUnit === VolumeUnit.MeterHour ? (parseFloat(newValues.volume) / 60).toString() : newValues.volume;
        const {fieldResult, pipeVolume, airVelocity} = calcPipeValue(selected, {...newValues, volume});
        newValues = {...newValues, [selected]: fieldResult?.toString() ?? ""}
        setPressureContainerValues(newValues);
        setResultValues({pipeVolume, airVelocity});
    };

    useEffect(() => {
        console.log(expanded);
        if(!expanded) reset();
    },[expanded])

    const reset = () => {
        setSelected(PipeCalcValue.Diameter);
        setPressureContainerValues(defaultPressureContainerValues)
        setResultValues({})
        setVolumeUnit(VolumeUnit.MeterMinute)
    }

    return (
        <CalcWrapper>
            <RadioGroup value={selected}
                        onChange={(e, value) => setSelected(value as PipeCalcValue)}>
                <Box>
                    <FormControlLabel value={PipeCalcValue.Volume} control={<Radio color={"primary"}/>} label="Volumen:"/>
                    <PipeVolumeUnitSelect value={volumeUnit} setValue={setVolumeUnit}/>
                </Box>
                <TextField id={"pipe-volume"} value={pressureContainerValues.volume}
                           disabled={selected === PipeCalcValue.Volume}
                           onChange={event => handleOnChange(event, "volume")} type={"number"}/>
                <FormControlLabel value={PipeCalcValue.Length} control={<Radio color={"primary"}/>} label="Länge [m]:"/>
                <TextField id={"pipe-length"} value={pressureContainerValues.length}
                           disabled={selected === PipeCalcValue.Length}
                           onChange={event => handleOnChange(event, "length")} type={"number"}/>
                <FormControlLabel value={PipeCalcValue.PressureLoss} control={<Radio color={"primary"}/>}
                                  label="Druckverluste [bar]:"/>
                <TextField id={"pipe-pressureLoss"} value={pressureContainerValues.pressureLoss}
                           disabled={selected === PipeCalcValue.PressureLoss}
                           onChange={event => handleOnChange(event, "pressureLoss")} type={"number"}/>
                <FormControlLabel value={PipeCalcValue.NetPressure} control={<Radio color={"primary"}/>}
                                  label="Netzdruck [barÜ]:"/>
                <TextField id={"pipe-netPressure"} value={pressureContainerValues.netPressure}
                           disabled={selected === PipeCalcValue.NetPressure}
                           onChange={event => handleOnChange(event, "netPressure")} type={"number"}/>
                <FormControlLabel value={PipeCalcValue.Diameter} control={<Radio color={"primary"}/>}
                                  label="Rohrinnendurchmesser di[mm]:"/>
                <TextField id={"pipe-diameter"} value={pressureContainerValues.diameter}
                           disabled={selected === PipeCalcValue.Diameter}
                           onChange={event => handleOnChange(event, "diameter")} type={"number"}/>
            </RadioGroup>
            {resultValues.pipeVolume && <Typography component={"span"}>
                Das Rohr hat ein Volumen von: {resultValues.pipeVolume} Liter
            </Typography>}
            {resultValues.airVelocity && <Typography component={"span"}>
                Die Luft Geschwindigkeit beträgt: {resultValues.airVelocity} [m/s]
            </Typography>}
        </CalcWrapper>
    );
};

export default PipeDiameter