import {ChangeEvent, FC} from "react";
import {Box, FormControl, FormControlLabel, MenuItem, Select} from "@material-ui/core";

interface LeakageMediumSelectProps {
    value: MediumValue;
    setValue: (value: MediumValue) => void;
}

export enum Medium {
    Luft = "Luft",
    Kohlenstoffdioxid = "Kohlenstoffdioxid",
    Sauerstoff = "Sauerstoff"
}

export type MediumValue = {
    medium: Medium;
    gas: number;
    kappa: number;
}

export const mediumValues: MediumValue[] = [
    {medium: Medium.Luft, gas: 287.1, kappa: 1.4},
    {medium: Medium.Kohlenstoffdioxid, gas: 188.9, kappa: 1.29},
    {medium: Medium.Sauerstoff, gas: 259.8, kappa: 1.4}
];

const LeakageMediumSelect: FC<LeakageMediumSelectProps> = ({value, setValue}) => {

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        const value = event.target.value as Medium;
        const mediumValue = mediumValues.find(v => v.medium === value);
        if (mediumValue) setValue(mediumValue);
    }

    return (
        <FormControl>
            <FormControlLabel control={<Box/>} label={"Medium:"} style={{margin: "0"}}/>
            <Select id={"leakage-medium-select"} onChange={handleChange}
                    value={value.medium}>
                {mediumValues.map(({medium}) => (
                    <MenuItem value={medium} key={medium}>{medium}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default LeakageMediumSelect