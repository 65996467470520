import {ChangeEvent, FC, useCallback, useEffect, useState} from "react";
import {CalcProps, ContainerLeakageKey, ContainerLeakageValues} from "../../../types/types";
import {calcLeakageValues, defaultLeakageValue} from "./ContainerLeakageHelper";
import FormLabelTextField from "../../../controls/FormLabelTextField";
import CalcWrapper from "../../../controls/CalcWrapper";
import {Button} from "@material-ui/core";
import {useMeasureTime} from "../../../hooks/useMeasureTime";

interface ContainerLeakageProps extends CalcProps{
}

const ContainerLeakage: FC<ContainerLeakageProps> = ({expanded}) => {
    const [leakageValues, setLeakageValues] = useState<ContainerLeakageValues>(defaultLeakageValue);
    const [leakageResult, setLeakageResult] = useState<string>("");

    const setMeasureTime = useCallback((time: number) => {
        setLeakageValues({...leakageValues, measureTime: time.toString()})
    },[leakageValues]);

    const {startTime, startTimer, stopTimer} = useMeasureTime(setMeasureTime);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: ContainerLeakageKey) => {
        const {value} = event.target;
        const newValues = {...leakageValues, [key]: value}
        setLeakageValues(newValues);
        const result = calcLeakageValues(newValues);
        setLeakageResult(result?.toString() ?? "");
    };

    useEffect(() => {
        if(!expanded) reset();
    },[expanded])

    const reset = () => {
        setLeakageValues(defaultLeakageValue);
        setLeakageResult("")
    }

    return (
        <CalcWrapper>
            <FormLabelTextField id={"container-leakage-volume"} value={leakageValues.volume}
                                label={"Behältervolumen [l]:"}
                                onChange={event => handleOnChange(event, "volume")}/>
            <FormLabelTextField id={"container-leakage-startPressure"} value={leakageValues.startPressure}
                                label={"Druckbehälteranfangsdruck [barÜ]:"}
                                onChange={event => handleOnChange(event, "startPressure")}/>
            <FormLabelTextField id={"container-leakage-endPressure"} value={leakageValues.endPressure}
                                label={"Druckbehälterenddruck [barÜ]:"}
                                onChange={event => handleOnChange(event, "endPressure")}/>
            <FormLabelTextField id={"container-leakage-measureTime"} value={leakageValues.measureTime}
                                label={"Messzeit [s]:"}
                                onChange={event => handleOnChange(event, "measureTime")}/>
            <Button variant="contained" color="primary" onClick={startTime ? stopTimer : startTimer}>
                {startTime ? "Stop" : "Start"}
            </Button>
            <FormLabelTextField id={"container-leakage-result"} value={leakageResult} label={"Leckagemenge [m3/min]:"}
                                disabled={true}/>
        </CalcWrapper>
    );
};

export default ContainerLeakage