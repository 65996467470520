import {FC, useState} from "react";
import UnitSelect, {Unit} from "./UnitSelect";
import TempUnit from "./TempUnit";
import PressureUnit from "./PressureUnit";
import VolumeUnit from "./VolumeUnit";
import WorkUnit from "./WorkUnit";
import FlowUnit from "./FlowUnit";
import CalcWrapper from "../../../controls/CalcWrapper";
import {CalcProps} from "../../../types/types";

interface UnitCalculatorProps extends CalcProps {
}

const UnitCalculator: FC<UnitCalculatorProps> = ({expanded}) => {
    const [selectedUnit, setSelectedUnit] = useState<Unit>(Unit.Druck);

    const getUnitControl = () => {
        switch (selectedUnit) {
            case Unit.Druck:
                return <PressureUnit/>
            case Unit.Volumen:
                return <VolumeUnit/>
            case Unit.Leistung:
                return <WorkUnit/>
            case Unit.Temperatur:
                return <TempUnit/>
            case Unit.Volumenstrom:
                return <FlowUnit/>
            default:
                return null;
        }
    };

    return (
        <CalcWrapper>
            <UnitSelect value={selectedUnit} setValue={setSelectedUnit}/>
            {getUnitControl()}
        </CalcWrapper>
    );
};

export default UnitCalculator