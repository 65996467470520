import {allStringSet, round} from "../../../utils/helper";

export enum FlowCalcValue {
    Volume = "volume",
    Velocity = "velocity",
    Area = "area"
}

const volumeValues = [0, 800, 1100, 1500, 2000, 2200, 2500, 3800, 4500, 5500, 6200, 6500, 8000, 8600000, 16000, 18000, 24400, 26000, 28000, 32000];

export const defaultFlowValues: FlowValues = {volume: "", velocity: "", area: "", length: "", width: "", diameter: "",}

export type FlowValues = {
    volume: string;
    velocity: string;
    area: string;
    length: string;
    width: string;
    diameter: string;
};

export type FlowValueKey = keyof FlowValues;

export const flowCalculator = (volume: number, velocity: number, area: number, selected: FlowCalcValue) => {
    let calcVolume = volume / 3600;
    let calcVelocity = velocity;
    let calcArea = area;
    switch (selected) {
        case FlowCalcValue.Volume:
            calcVolume = calcVelocity * calcArea;
            return round(calcVolume * 3600, 2);
        case FlowCalcValue.Velocity:
            calcVelocity = calcVolume / calcArea;
            return round(calcVelocity, 2);
        case FlowCalcValue.Area:
            calcArea = calcVolume / calcVelocity;
            return round(calcArea, 2);
        default:
            return 0;
    }
};

export const calcArea = (values: FlowValues, key: FlowValueKey) => {
    const {width, length, diameter} = values;
    switch (key) {
        case "width":
        case "length":
            return {...values, area: round(parseFloat(length) * parseFloat(width), 2).toString()}
        case "diameter":
            return {...values, area: round(Math.pow(parseFloat(diameter) / 2, 2) * Math.PI, 2).toString()}
        default:
            return values;
    }
};

export const calcFlowValues = (values: FlowValues, selected: FlowCalcValue) => {
    const {volume, velocity, area} = values;
    let selectedVolume: string | undefined;
    let result = 0;
    switch (selected) {
        case FlowCalcValue.Volume:
            if (allStringSet(velocity, area)) {
                result = flowCalculator(parseFloat(volume), parseFloat(velocity), parseFloat(area), selected);
                selectedVolume = volumeValues.reduce((prev, curr) => {
                    return (Math.abs(curr - result) < Math.abs(prev - result) ? curr : prev);
                }).toString();
            }
            break;
        case FlowCalcValue.Area:
            if (allStringSet(volume, velocity))
                result = flowCalculator(parseFloat(volume), parseFloat(velocity), parseFloat(area), selected);
            break;
        case FlowCalcValue.Velocity:
            if (allStringSet(volume, area))
                result = flowCalculator(parseFloat(volume), parseFloat(velocity), parseFloat(area), selected);
            break;
    }
    return {flowValues: {...values, [selected]: result.toString()}, selectedVolume};
};