import {FC, useState} from "react";
import {round} from "../../../utils/helper";
import {handleUnit} from "./UnitHelper";
import FormLabelTextField from "../../../controls/FormLabelTextField";

interface FlowUnitProps {
}

enum FlowType {
    MeterSecond,
    MeterMinute,
    MeterHour,
    LiterSecond,
    LiterMinute,
}

const MULTIPLIER = [1, 60, 3600, 1000, 60000];

const FlowUnit: FC<FlowUnitProps> = () => {
    const [meterSecond, setMeterSecond] = useState<string>("");
    const [meterMinute, setMeterMinute] = useState<string>("");
    const [meterHour, setMeterHour] = useState<string>("");
    const [literSecond, setLiterSecond] = useState<string>("");
    const [literMinute, setLiterMinute] = useState<string>("");

    const setUnit = (value: number, type: FlowType) => {
        let currentMultiplier = MULTIPLIER[type];
        switch (type) {
            case FlowType.MeterSecond:
                setMeterSecond(round(value / currentMultiplier, 3).toString());
                break;
            case FlowType.MeterMinute:
                setMeterMinute(round((value / currentMultiplier) * MULTIPLIER[1], 3).toString());
                break;
            case FlowType.MeterHour:
                setMeterHour(round((value / currentMultiplier) * MULTIPLIER[2], 3).toString());
                break;
            case FlowType.LiterSecond:
                setLiterSecond(round((value / currentMultiplier) * MULTIPLIER[3], 3).toString());
                break;
            case FlowType.LiterMinute:
                setLiterMinute(round((value / currentMultiplier) * MULTIPLIER[4], 3).toString());
                break;
        }
    };

    return (
        <>
            <FormLabelTextField value={meterSecond} label={"m³/s"}
                                onChange={event => handleUnit(event.target.value, setMeterSecond, FlowType.MeterSecond, setUnit)}/>
            <FormLabelTextField value={meterMinute} label={"m³/min"}
                                onChange={event => handleUnit(event.target.value, setMeterMinute, FlowType.MeterMinute, setUnit)}/>
            <FormLabelTextField value={meterHour} label={"m³/h"}
                                onChange={event => handleUnit(event.target.value, setMeterHour, FlowType.MeterHour, setUnit)}/>
            <FormLabelTextField value={literSecond} label={"l/s"}
                                onChange={event => handleUnit(event.target.value, setLiterSecond, FlowType.LiterSecond, setUnit)}/>
            <FormLabelTextField value={literMinute} label={"l/min"}
                                onChange={event => handleUnit(event.target.value, setLiterMinute, FlowType.LiterMinute, setUnit)}/>
        </>
    );
};

export default FlowUnit