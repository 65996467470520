import {ChangeEvent, FC, useEffect, useState} from "react";
import {CalcProps, CondensateKey, CondensateResultValues, CondensateValues} from "../../../types/types";
import {calcCondensateResult, defaultCondensateResultValues, defaultCondensateValues} from "./CondensateHelper";
import FormLabelTextField from "../../../controls/FormLabelTextField";
import CalcWrapper from "../../../controls/CalcWrapper";

interface CondensateProps extends CalcProps{
}

const Condensate: FC<CondensateProps> = ({expanded}) => {
    const [condensateValues, setCondensateValues] = useState<CondensateValues>(defaultCondensateValues);
    const [resultValues, setResultValues] = useState<CondensateResultValues>(defaultCondensateResultValues);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: CondensateKey) => {
        const {value} = event.target;
        const newValues = {...condensateValues, [key]: value};

        if (key === "airTemp")
            newValues.coolTemp = (parseFloat(value) + 10).toString();

        const resultValues = calcCondensateResult(newValues);
        setCondensateValues(newValues);
        setResultValues(resultValues);
    }

    useEffect(() => {
        console.log(expanded);
        if(!expanded) reset();
    },[expanded])

    const reset = () => {
        setCondensateValues(defaultCondensateValues);
        setResultValues(defaultCondensateResultValues)
    }

    return (
        <CalcWrapper>
            <FormLabelTextField label={"Umgebungstemperatur [°C]:"} id={"condensate-air-temp"}
                                value={condensateValues.airTemp}
                                onChange={event => handleOnChange(event, "airTemp")}/>
            <FormLabelTextField id={"condensate-humidity"} value={condensateValues.humidity}
                                label={"Relative Feuchte der Ansaugluft [%]:"}
                                onChange={event => handleOnChange(event, "humidity")}/>
            <FormLabelTextField id={"condensate-air-delivery"} value={condensateValues.airDelivery}
                                label={"Liefermenge des Kompressors [m³/min]:"}
                                onChange={event => handleOnChange(event, "airDelivery")}/>
            <FormLabelTextField id={"condensate-pressure"} value={condensateValues.pressure}
                                label={"Verdichtungsenddruck [barÜ]:"}
                                onChange={event => handleOnChange(event, "pressure")}/>
            <FormLabelTextField id={"condensate-cool-temp"} value={condensateValues.coolTemp}
                                label={"Kompressor Austrittstemperatur [°C]:"}
                                onChange={event => handleOnChange(event, "coolTemp")}/>
            <FormLabelTextField id={"condensate-dtp"} value={condensateValues.dtp}
                                label={"Drucktaupunkt [°C]:"}
                                onChange={event => handleOnChange(event, "dtp")}/>
            <FormLabelTextField id={"condensate-hours"} value={condensateValues.hours}
                                label={"Stunden :"}
                                onChange={event => handleOnChange(event, "hours")}/>
            <FormLabelTextField id={"condensate-water-amount"} value={resultValues.waterAmount}
                                label={"Angesaugte Wasser Menge [l/h]:"} disabled={true}/>
            <FormLabelTextField id={"condensate-condensate-loss"} value={resultValues.condensateLoss}
                                label={"Kondensat Ausfall nach Verdichtung [l/h]:"} disabled={true}/>
            <FormLabelTextField id={"condensate-remaining-humidity"} value={resultValues.remainingHumidity}
                                label={"Restfeuchte [l/h]:"} disabled={true}/>
            <FormLabelTextField id={"condensate-cold-loss"} value={resultValues.coldLoss}
                                label={"Kondensat nach Kältetrockner [l/h]:"} disabled={true}/>
            <FormLabelTextField id={"condensate-total-loss"} value={resultValues.totalLoss}
                                label={"Kondensat Ausfall insgesamt [l/h]:"} disabled={true}/>
            <FormLabelTextField id={"condensate-cold-humidity"} value={resultValues.coldHumidity}
                                label={"Restfeuchte nach Kältetrockner [l/h]:"} disabled={true}/>
        </CalcWrapper>
    );
};

export default Condensate