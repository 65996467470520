import {FC} from "react";
import {Box, FormControl, makeStyles} from "@material-ui/core";

interface CalcWrapperProps {
}

const useStyles = makeStyles({
    wrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    form: {
        width: "80%"
    }
})

const CalcWrapper: FC<CalcWrapperProps> = ({children}) => {
    const classes = useStyles();
    return (
        <Box className={classes.wrapper}>
            <FormControl className={classes.form}>
                {children}
            </FormControl>
        </Box>
    );
};

export default CalcWrapper