import {FC, useState} from "react";
import {round} from "../../../utils/helper";
import {handleUnit} from "./UnitHelper";
import FormLabelTextField from "../../../controls/FormLabelTextField";

interface TempUnitProps {
}

enum TempType {
    Celsius,
    Kelvin,
    Fahrenheit
}

const TempUnit: FC<TempUnitProps> = () => {
    const [celsius, setCelsius] = useState<string>("");
    const [kelvin, setKelvin] = useState<string>("");
    const [fahrenheit, setFahrenheit] = useState<string>("");

    const setUnit = (value: number, type: TempType) => {
        switch (type) {
            case TempType.Celsius:
                setKelvin(round(value + 273.15, 3).toString());
                setFahrenheit(round(value * (9 / 5) + 32, 3).toString());
                break;
            case TempType.Kelvin:
                setCelsius(round(value - 273.15, 3).toString());
                setFahrenheit(round((value - 273.15) * (9 / 5) + 32, 3).toString());
                break;
            case TempType.Fahrenheit:
                setCelsius(round((value - 32) * 5 / 9, 3).toString());
                setKelvin(round((value - 32) * 5 / 9 + 273.15, 3).toString());
                break;
        }
    };
    return (
        <>
            <FormLabelTextField value={celsius} label={"Celsius"}
                                onChange={event => handleUnit(event.target.value, setCelsius, TempType.Celsius, setUnit)}/>
            <FormLabelTextField value={kelvin} label={"Kelvin"}
                                onChange={event => handleUnit(event.target.value, setKelvin, TempType.Kelvin, setUnit)}/>
            <FormLabelTextField value={fahrenheit} label={"Fahrenheit"}
                                onChange={event => handleUnit(event.target.value, setFahrenheit, TempType.Fahrenheit, setUnit)}/>
        </>
    );
};

export default TempUnit