import {FC, useState} from "react";
import {round} from "../../../utils/helper";
import {handleUnit} from "./UnitHelper";
import FormLabelTextField from "../../../controls/FormLabelTextField";

interface PressureUnitProps {
}

enum PressureType {
    Kpa,
    Bar,
    Psi
}

const MULTIPLIER = [1, 0.01, 0.145];

const PressureUnit: FC<PressureUnitProps> = () => {
    const [kpa, setKpa] = useState<string>("");
    const [bar, setBar] = useState<string>("");
    const [psi, setPsi] = useState<string>("");

    const setUnit = (value: number, type: PressureType) => {
        let currentMultiplier = MULTIPLIER[type];
        if (type !== 0)
            setKpa(round(value / currentMultiplier, 3).toString());
        if (type !== 1)
            setBar(round((value / currentMultiplier) * MULTIPLIER[1], 3).toString());
        if (type !== 2)
            setPsi(round((value / currentMultiplier) * MULTIPLIER[2], 3).toString());
    };

    return (
        <>
            <FormLabelTextField value={kpa} label={"kpa"}
                                onChange={event => handleUnit(event.target.value, setKpa, PressureType.Kpa, setUnit)}/>
            <FormLabelTextField value={bar} label={"Bar"}
                                onChange={event => handleUnit(event.target.value, setBar, PressureType.Bar, setUnit)}/>
            <FormLabelTextField value={psi} label={"Psi"}
                                onChange={event => handleUnit(event.target.value, setPsi, PressureType.Psi, setUnit)}/>
        </>
    );
};

export default PressureUnit