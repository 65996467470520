import {CalcDescription} from "../../types/types";
import {FC, useState} from "react";
import {Paper} from "@material-ui/core";
import CalcAccordion from "./CalcAccordion";
import {Calculator} from "../../types/enum";

const calculators: CalcDescription[] = [
    {
        url: "https://le-cdn.website-editor.net/6c5c30bc2d0f4fe98444bffb28b94f2f/dms3rep/multi/opt/ASD_60_012-637d97bb-1920w.png",
        description: "Berechnung von Kompressorleistung und abzuführender Wärme.",
        title: "Kompressor Leistung",
        calculator: Calculator.CompressorWork
    },
    {
        url: "https://le-cdn.website-editor.net/md/and1/dms3rep/multi/opt/114395-1920w.jpeg",
        description: "Berechnung von Rohrleitungssystemen nach Volumenstrom, Druckverluste, Querschnitte und Längen.",
        title: "Druckluft-Rohrleitung",
        calculator: Calculator.PipeDiameter
    },
    {
        url: "https://le-cdn.website-editor.net/md/and1/dms3rep/multi/opt/112551-1920w.jpeg",
        description: "Berechnung von Zu- und Abluftsystemen zur Kühlung, Belüftung und Lufttauschsystemen.",
        title: "Lüftungstechnik",
        calculator: Calculator.Flow
    },
    {
        url: "https://le-cdn.website-editor.net/6c5c30bc2d0f4fe98444bffb28b94f2f/dms3rep/multi/opt/schema-86ddf435-1920w.jpg",
        description: "Kondensat-Kalkulation für die Berechnung von Kondensatmengen und Aufbereitungssytemen.",
        title: "Druckluft-Kondensat",
        calculator: Calculator.Condensate
    },
    {
        url: "https://le-cdn.website-editor.net/6c5c30bc2d0f4fe98444bffb28b94f2f/dms3rep/multi/opt/produktbild1-336f5663-1920w.png",
        description: "Behälterberechnung nach Volumen, Druck und Wechselbelastung.",
        title: "Druckluft-Behälter",
        calculator: Calculator.PressureContainer
    },
    {
        url: "https://le-cdn.website-editor.net/6c5c30bc2d0f4fe98444bffb28b94f2f/dms3rep/multi/opt/R51d4ac404a175e43b456aefd0a1aa912-1920w.png",
        description: "Berechnung der spezifischen Leistung von Verdichtersystemen.",
        title: "Verdichtungsarbeit",
        calculator: Calculator.IncreasedWork
    },
    {
        url: "https://cdn.website-editor.net/6c5c30bc2d0f4fe98444bffb28b94f2f/dms3rep/multi/OIP+%282%29.gif",
        description: "",
        title: "Düse/Leckage",
        calculator: Calculator.Leakage
    },
    {
        url: "https://le-cdn.website-editor.net/md/and1/dms3rep/multi/opt/113960-1920w.jpeg",
        description: "Leckageermittlung mittels Behältervolumen und Drucksenkung.",
        title: "Behälter Leckage Messung",
        calculator: Calculator.ContainerLeakage
    },
    {
        url: "https://le-cdn.website-editor.net/6c5c30bc2d0f4fe98444bffb28b94f2f/dms3rep/multi/opt/wp_file_20008-10b05ba4-1920w.png",
        description: "Bar oder PSI? Kubikmeter oder Liter? Unser Umrechnungstool kennt alle internationalen Standard-Einheiten für:\n" +
            "Druck\n" +
            "Volumen\n" +
            "Leistung\n" +
            "Temperatur\n" +
            "Volumenstrom",
        title: "Einheiten Rechner",
        calculator: Calculator.UnitCalculator
    }
]

interface CalculatorsProps {
}

const Calculators: FC<CalculatorsProps> = () => {
    const [expanded, setExpanded] = useState<number>();

    const toggleCalc = (index: number) => {
        if (expanded === index) setExpanded(undefined);
        else setExpanded(index);
    }

    return (
        <Paper>
            {calculators.map((calc, index) => (
                <CalcAccordion expanded={expanded === index} calc={calc} key={index}
                               setExpanded={() => toggleCalc(index)}/>
            ))}
        </Paper>
    );
};

export default Calculators