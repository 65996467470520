import {FC, useMemo} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {CalcDescription} from "../../types/types";
import {makeStyles} from "@material-ui/styles";
import {Calculator} from "../../types/enum";
import PipeDiameter from "./PipeDiameter/PipeDiameter";
import Leakage from "./Leakage/Leakage";
import PressureContainer from "./PressureContainer/PressureContainer";
import ContainerLeakage from "./ContainerLeakage/ContainerLeakage";
import UnitCalculator from "./UnitCalculator/UnitCalculator";
import Condensate from "./Condensate/Condensate";
import IncreasedWork from "./IncreasedWork/IncreasedWork";
import Flow from "./Flow/Flow";

interface CalcAccordionProps {
    calc: CalcDescription;
    expanded: boolean;
    setExpanded: () => void;
}

const useStyles = makeStyles({
    header: {
        display: "flex"
    },
    title: {
        borderBottom: "1px solid black"
    },
    image: {
        width: "195px",
        height: "195px"
    },
    text: {
        marginLeft: "20px"
    }
});

const CalcAccordion: FC<CalcAccordionProps> = ({calc, expanded, setExpanded}) => {
    const {url, calculator, description, title} = calc;

    const component = useMemo(() => {
        switch (calculator) {
            case Calculator.CompressorWork:
                return <div>Platzhalter</div>
            case Calculator.PipeDiameter:
                return <PipeDiameter expanded={expanded}/>
            case Calculator.Flow:
                return <Flow expanded={expanded}/>
            case Calculator.Condensate:
                return <Condensate expanded={expanded}/>
            case Calculator.PressureContainer:
                return <PressureContainer expanded={expanded}/>
            case Calculator.IncreasedWork:
                return <IncreasedWork expanded={expanded}/>
            case Calculator.Leakage:
                return <Leakage expanded={expanded}/>
            case Calculator.ContainerLeakage:
                return <ContainerLeakage expanded={expanded}/>
            case Calculator.UnitCalculator:
                return <UnitCalculator expanded={expanded}/>
        }
    }, [calculator, expanded]);

    const classes = useStyles();
    return (
        <Accordion expanded={expanded} onChange={setExpanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
                <Box className={classes.header}>
                    <img alt={title} src={url} className={classes.image}/>
                    <Box className={classes.text}>
                        <Typography variant={"h5"} className={classes.title}>{title}</Typography>
                        <Typography>{description}</Typography>
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {component}
            </AccordionDetails>
        </Accordion>
    );
};

export default CalcAccordion