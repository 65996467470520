import {useEffect, useState} from "react";

export const useMeasureTime = (setTime: (time: number) => void) => {
    const [startTime, setStartTime] = useState<Date>();

    useEffect(() => {
        if (!startTime) return;
        const timer = setInterval(() => {
            const currentTime = new Date();
            const time = currentTime.getTime() - startTime.getTime();
            setTime(Math.round(time / 1000));
        }, 50);
        return () => clearInterval(timer);
    }, [startTime, setTime]);

    const startTimer = () => {
        setStartTime(new Date());
    };

    const stopTimer = () => {
        setStartTime(undefined);
    };

    return {startTime, startTimer, stopTimer}
};