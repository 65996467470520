import {pressureContainerValues} from "../../../types/types";
import {allStringSet, round} from "../../../utils/helper";

export enum PipeCalcValue {
    Volume = "volume",
    Length = "length",
    PressureLoss = "pressureLoss",
    NetPressure = "netPressure",
    Diameter = "diameter"
}

export const defaultPressureContainerValues = {volume: "", length: "", pressureLoss: "", netPressure: "", diameter: ""};

export const calcPipeValue = (selected: PipeCalcValue, values: pressureContainerValues) => {
    const {volume, length, diameter, netPressure, pressureLoss} = values;
    switch (selected) {
        case PipeCalcValue.Volume:
            if(allStringSet(length, pressureLoss, netPressure, diameter))
                return pipeCalculator(parseFloat(volume), parseFloat(length), parseFloat(pressureLoss), parseFloat(netPressure), parseFloat(diameter), selected);
            break;
        case PipeCalcValue.Length:
            if(allStringSet(volume, pressureLoss, netPressure, diameter))
                return pipeCalculator(parseFloat(volume), parseFloat(length), parseFloat(pressureLoss), parseFloat(netPressure), parseFloat(diameter), selected);
            break;
        case PipeCalcValue.PressureLoss:
            if(allStringSet(length, volume, netPressure, diameter))
                return pipeCalculator(parseFloat(volume), parseFloat(length), parseFloat(pressureLoss), parseFloat(netPressure), parseFloat(diameter), selected);
            break;
        case PipeCalcValue.NetPressure:
            if(allStringSet(length, volume, pressureLoss, diameter))
                return pipeCalculator(parseFloat(volume), parseFloat(length), parseFloat(pressureLoss), parseFloat(netPressure), parseFloat(diameter), selected);
            break;
        case PipeCalcValue.Diameter:
            if(allStringSet(length, volume, pressureLoss, netPressure))
                return pipeCalculator(parseFloat(volume), parseFloat(length), parseFloat(pressureLoss), parseFloat(netPressure), parseFloat(diameter), selected);
            break;
    }
    return {fieldResult: undefined, airVelocity: undefined, pipeVolume: undefined};
}

export const pipeCalculator = (volume: number, length: number, pressureLoss: number, netPressure: number, diameter: number, selected: PipeCalcValue) => {
    let calcVolume = volume / 60;
    let calcDiameter = diameter / 1000;
    let calcNetPressure = netPressure + 1;
    let calcPressureLoss = pressureLoss;
    let calcLength = length;
    let fieldResult: number;
    switch (selected) {
        case PipeCalcValue.Volume:
            calcVolume = (Math.pow((Math.pow(calcDiameter, 5) * Math.pow(10, 10) * calcPressureLoss * calcNetPressure) / (1.6 * Math.pow(10, 3) * calcLength), 1 / 1.85));
            fieldResult = calcVolume * 60;
            break;
        case PipeCalcValue.Length:
            calcLength = ((Math.pow(calcDiameter, 5) * Math.pow(10, 10) * calcPressureLoss * calcNetPressure) / (1.6 * Math.pow(10, 3) * Math.pow(calcVolume, 1.85)));
            fieldResult = calcLength;
            break;
        case PipeCalcValue.PressureLoss:
            calcPressureLoss = ((1.6 * Math.pow(10, 3) * Math.pow(calcVolume, 1.85) * calcLength) / (Math.pow(calcDiameter, 5) * Math.pow(10, 10) * calcNetPressure));
            fieldResult = calcPressureLoss;
            break;
        case PipeCalcValue.NetPressure:
            calcNetPressure = ((1.6 * Math.pow(10, 3) * Math.pow(calcVolume, 1.85) * calcLength) / (Math.pow(calcDiameter, 5) * Math.pow(10, 10) * calcPressureLoss));
            fieldResult = calcNetPressure - 1;
            break;
        case PipeCalcValue.Diameter:
            calcDiameter = (Math.pow((1.6 * Math.pow(10, 3) * Math.pow(calcVolume, 1.85) * calcLength) / (Math.pow(10, 10) * calcPressureLoss * calcNetPressure), 0.2));
            fieldResult = calcDiameter * 1000;
            break;
        default:
            fieldResult = 0;
    }
    const pipeArea = Math.pow(calcDiameter * 1000 / 2, 2) * Math.PI;
    const pipeVolume = round(((pipeArea / 10000) * (calcLength * 10)), 2);
    const airVelocity = round(calcVolume / (pipeArea / 1000000) / (calcNetPressure), 2);
    fieldResult = round(fieldResult, 2);
    return {fieldResult, pipeVolume, airVelocity};
};