import {ChangeEvent, FC} from "react";
import {FormControl, MenuItem, Select} from "@material-ui/core";

interface FlowVelocitySelectProps {
    value: string;
    setValue: (value: string) => void;
    disabled: boolean;
}

const FlowVelocitySelect : FC<FlowVelocitySelectProps> = ({value, setValue, disabled}) => {

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
    }

    return (
        <FormControl>
            <Select labelId={"flow-velocity-select-label"} id={"flow-velocity-select"} onChange={handleChange}
                    value={value} disabled={disabled}>
                <MenuItem value="0">Keine Auswahl</MenuItem>
                <MenuItem value="4.0">Abluft</MenuItem>
                <MenuItem value="2.0">Zuluft</MenuItem>
            </Select>
        </FormControl>
    );
};

export default FlowVelocitySelect