export enum Calculator {
    CompressorWork = "compressor-work",
    PipeDiameter = "pipe-diameter",
    Flow = "flow",
    Condensate = "condensate",
    PressureContainer = "pressure-container",
    IncreasedWork = "increased-work",
    Leakage = "leakage",
    ContainerLeakage = "container-leakage",
    UnitCalculator = "unit-calculator"
}