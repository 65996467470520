import {FC, useState} from "react";
import {round} from "../../../utils/helper";
import {handleUnit} from "./UnitHelper";
import FormLabelTextField from "../../../controls/FormLabelTextField";

interface VolumeUnitProps {
}

enum VolumeType {
    Meter,
    Liter
}

const MULTIPLIER = [1, 1000]

const VolumeUnit: FC<VolumeUnitProps> = () => {
    const [meter, setMeter] = useState<string>("");
    const [liter, setLiter] = useState<string>("");

    const setUnit = (value: number, type: VolumeType) => {
        let currentMultiplier = MULTIPLIER[type];
        switch (type) {
            case VolumeType.Liter:
                setLiter(round((value / currentMultiplier) * MULTIPLIER[1], 3).toString());
                break;
            case VolumeType.Meter:
                setMeter(round(value / currentMultiplier, 3).toString());
                break;
        }
    };

    return (
        <>
            <FormLabelTextField value={meter} label={"m³/s"}
                                onChange={event => handleUnit(event.target.value, setMeter, VolumeType.Meter, setUnit)}/>
            <FormLabelTextField value={liter} label={"m³/min"}
                                onChange={event => handleUnit(event.target.value, setLiter, VolumeType.Liter, setUnit)}/>
        </>
    );
};

export default VolumeUnit