export type IncreasedWorkValues = {
    pressureP1: string;
    temp: string;
    increasedWork: string;
};

export type IncreasedWorkValuesKey = keyof IncreasedWorkValues;

export const defaultIncreasedWorkValues: IncreasedWorkValues = {pressureP1: "", temp: "20", increasedWork: "0"};

export const increasedWorkCalculator = (pressureP1: number, temperature: number) => {
    const pressureP2 = 1;
    const T2 = 293;
    const KAPPA = 1.4;
    const t0 = 273 + temperature;
    const kappa2 = 1 / (KAPPA / (KAPPA - 1));
    const t1 = T2 * Math.pow((pressureP1 / pressureP2), kappa2);
    return 1 / ((1 - t0 / t1) / (1 - T2 / t1));
};