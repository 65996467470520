import {FC} from "react";
import {Box, createMuiTheme, ThemeProvider} from "@material-ui/core";
import Calculators from "./Calculators/Calculators";

const show = false;

const theme = createMuiTheme({
    palette:{
        primary: {
            main: "#FF0000"
        }
    }
});

const Main: FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box>
                <Calculators/>
                {show && <iframe title={"clip-systems"} width={"1000px"} height={"800px"}
                                 src={"https://clip-calc.clip-systems.de/?calc=condensate"}/>}
            </Box>
        </ThemeProvider>
    );
};

export default Main;