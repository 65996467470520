import {round} from "../../../utils/helper";
import {PressureContainerValues} from "../../../types/types";

export enum PressureContainerValue {
    MotorCycle= "motorCycle",
    Volume = "volume"
}

export const containerCalculator = (deliveredAmount: number, minAmount: number, motorCycle: number, turnOffPressure: number, turnOnPressure: number, volume: number, toCalc: PressureContainerValue) => {
    const mVolume = volume / 1000;
    switch (toCalc) {
        case PressureContainerValue.Volume:
            return 1000 * round((deliveredAmount * 60 * ((minAmount / deliveredAmount) - Math.pow((minAmount / deliveredAmount), 2))) / (motorCycle * (turnOffPressure - turnOnPressure)), 2);
        case PressureContainerValue.MotorCycle:
            return round(((deliveredAmount * 60 * ((minAmount / deliveredAmount) - Math.pow((minAmount / deliveredAmount), 2))) / (mVolume * (turnOffPressure - turnOnPressure))), 2);
        default:
            return '';
    }
};

export const calcPressureContainerValues = (values: PressureContainerValues, selected: PressureContainerValue) => {
    const {deliveredAmount, minAmount, motorCycle, turnOffPressure, turnOnPressure, volume} = values;
    switch (selected) {
        case PressureContainerValue.Volume:
            if (deliveredAmount !== '' && minAmount !== '' && motorCycle !== '' && turnOffPressure !== '' && turnOnPressure !== '')
               return containerCalculator(parseFloat(deliveredAmount), parseFloat(minAmount), parseFloat(motorCycle), parseFloat(turnOffPressure), parseFloat(turnOnPressure), 0, selected);
            return;
        case PressureContainerValue.MotorCycle:
            if (deliveredAmount !== '' && minAmount !== '' && volume !== '' && turnOffPressure !== '' && turnOnPressure !== '')
                return containerCalculator(parseFloat(deliveredAmount), parseFloat(minAmount), 0, parseFloat(turnOffPressure), parseFloat(turnOnPressure), parseFloat(volume), selected);
            return;
    }
};

export const defaulPressureContainertValues: PressureContainerValues = {
    deliveredAmount: '',
    minAmount: '',
    motorCycle: '',
    turnOffPressure: '',
    turnOnPressure: '',
    volume: ''
};
