import {FC, ChangeEvent} from "react";
import {FormControl, MenuItem, Select} from "@material-ui/core";

interface FlowVolumeSelectProps {
    value: string;
    setValue: (value: string) => void;
    disabled: boolean;
}

const FlowVolumeSelect: FC<FlowVolumeSelectProps> = ({value, setValue,disabled}) => {

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
    }

    return (
        <FormControl>
            <Select labelId={"flow-volume-select-label"} id={"flow-volume-select"} onChange={handleChange}
                    value={value} disabled={disabled}>
                <MenuItem value="0">Keine Auswahl</MenuItem>
                <MenuItem value="800">3 kw [800]</MenuItem>
                <MenuItem value="1100">4 kw [1100]</MenuItem>
                <MenuItem value="1500">5,5 kw [1500]</MenuItem>
                <MenuItem value="2000">7,5 kw [2000]</MenuItem>
                <MenuItem value="2200">11 kw [2200]</MenuItem>
                <MenuItem value="2500">15 kw [2500]</MenuItem>
                <MenuItem value="3800">18,5 kw [3800]</MenuItem>
                <MenuItem value="4500">22 kw [4500]</MenuItem>
                <MenuItem value="5500">30 kw [5500]</MenuItem>
                <MenuItem value="6200">37 kw [6200]</MenuItem>
                <MenuItem value="6500">45 kw [6500]</MenuItem>
                <MenuItem value="8000">55 kw [8000]</MenuItem>
                <MenuItem value="8600">65 kw [8600]</MenuItem>
                <MenuItem value="10000">75 kw [10000]</MenuItem>
                <MenuItem value="16000">90 kw [16000]</MenuItem>
                <MenuItem value="18000">110 kw [18000]</MenuItem>
                <MenuItem value="24400">132 kw [24400]</MenuItem>
                <MenuItem value="26000">160 kw [26000]</MenuItem>
                <MenuItem value="28000">200 kw [28000]</MenuItem>
                <MenuItem value="32000">250 kw [32000]</MenuItem>
            </Select>
        </FormControl>
    );
};

export default FlowVolumeSelect