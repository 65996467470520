import pressureTempTable from '../../../resources/PressureTemTable.json';
import {CondensateResultValues, CondensateValues} from "../../../types/types";
import {allStringSet, round} from "../../../utils/helper";

export const calcWaterAmount = (airTemp: number, humidity: number, airDelivery: number, pressure: number = 1): number => {
    const {table} = pressureTempTable;
    const entry = table.find(entry => entry.temp === Math.round(airTemp));
    let saturation = 0;
    if (!entry) {
        if (airTemp <= table[0].temp)
            saturation = table[0].saturation;
        else if (airTemp >= table[table.length - 1].temp)
            saturation = table[table.length - 1].saturation;
    } else
        saturation = entry.saturation;
    return (saturation * (humidity / 100) * airDelivery * 60) / (pressure * 1000)
};

export const calcCondensateResult = (values: CondensateValues) => {
    const {airTemp, humidity, airDelivery, pressure, coolTemp, dtp, hours} = values;
    let waterAmount = 0;
    if (allStringSet(airTemp, humidity, airDelivery))
        waterAmount = calcWaterAmount(parseFloat(airTemp), parseFloat(humidity), parseFloat(airDelivery));
    let remainingHumidity = 0
    let condensateLoss = 0;
    if (allStringSet(pressure, coolTemp) && waterAmount !== 0) {
        remainingHumidity = calcWaterAmount(parseFloat(coolTemp), 100, parseFloat(airDelivery), parseFloat(pressure))
        condensateLoss = waterAmount - remainingHumidity;
    }
    let coldHumidity = 0;
    let coldLoss = 0;
    if (allStringSet(dtp, pressure) && remainingHumidity !== 0) {
        coldHumidity = calcWaterAmount(parseFloat(dtp), 100, parseFloat(airDelivery), parseFloat(pressure));
        coldLoss = remainingHumidity - coldHumidity;
    }
    const multiplier = parseInt(hours);
    if (multiplier > 1) {
        waterAmount *= multiplier;
        condensateLoss *= multiplier;
        coldHumidity *= multiplier;
        remainingHumidity *= multiplier;
        coldLoss *= multiplier;
    }
    let totalLoss = 0;
    if (waterAmount !== 0 && coldLoss !== 0)
        totalLoss = waterAmount - coldLoss;

    return {
        waterAmount: roundToString(waterAmount),
        condensateLoss: roundToString(condensateLoss),
        remainingHumidity: roundToString(remainingHumidity),
        coldLoss: roundToString(coldLoss),
        coldHumidity: roundToString(coldHumidity),
        totalLoss: roundToString(totalLoss)
    };
};

const roundToString = (value: number) => round(value, 3).toString();

export const defaultCondensateValues: CondensateValues = {
    airTemp: '20',
    humidity: '50',
    airDelivery: '',
    pressure: '7',
    coolTemp: '50',
    dtp: '5',
    hours: '1',
};

export const defaultCondensateResultValues: CondensateResultValues = {
    waterAmount: "",
    condensateLoss: "",
    remainingHumidity: "",
    coldLoss: "",
    coldHumidity: "",
    totalLoss: ""
};