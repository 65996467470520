import {ChangeEvent, FC, useEffect, useState} from "react";
import {CalcProps, PressureContainerKey, PressureContainerValues} from "../../../types/types";
import {
    calcPressureContainerValues,
    defaulPressureContainertValues,
    PressureContainerValue
} from "./PressureContainerHelper";
import {FormControlLabel, Radio, RadioGroup, TextField} from "@material-ui/core";
import FormLabelTextField from "../../../controls/FormLabelTextField";
import CalcWrapper from "../../../controls/CalcWrapper";

interface PressureContainerProps extends CalcProps {
}

const PressureContainer: FC<PressureContainerProps> = ({expanded}) => {
    const [selected, setSelected] = useState<PressureContainerValue>(PressureContainerValue.Volume);
    const [pressureContainerValues, setPressureContainerValues] = useState<PressureContainerValues>(defaulPressureContainertValues);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: PressureContainerKey) => {
        const {value} = event.target;
        let newValues = {...pressureContainerValues, [key]: value}
        const result = calcPressureContainerValues(pressureContainerValues, selected);
        newValues = {...newValues, [selected]: result?.toString() ?? ""}
        setPressureContainerValues(newValues);
    };

    useEffect(() => {
        console.log(expanded);
        if(!expanded) reset();
    },[expanded])

    const reset = () => {
        setSelected(PressureContainerValue.Volume);
        setPressureContainerValues(defaulPressureContainertValues)
    }

    return (
        <CalcWrapper>
            <FormLabelTextField id={"pressure-container-deliveredAmount"}
                                value={pressureContainerValues.deliveredAmount}
                                onChange={event => handleOnChange(event, "deliveredAmount")}
                                label={"Liefermenge des Kompressors [m³/min]:"}/>
            <FormLabelTextField id={"pressure-container-minAmount"} value={pressureContainerValues.minAmount}
                                onChange={event => handleOnChange(event, "minAmount")}
                                label={"Benötigte Liefermenge [m³/min]:"}/>
            <FormLabelTextField id={"pressure-container-turnOffPressure"}
                                value={pressureContainerValues.turnOffPressure}
                                onChange={event => handleOnChange(event, "turnOffPressure")}
                                label={"Ausschaltdruck des Kompressors [barÜ]:"}/>
            <FormLabelTextField id={"pressure-container-turnOnPressure"}
                                value={pressureContainerValues.turnOnPressure}
                                onChange={event => handleOnChange(event, "turnOnPressure")}
                                label={"Einschaltdruck des Kompressors [barÜ]:"}/>
            <RadioGroup value={selected}
                        onChange={(e, value) => setSelected(value as PressureContainerValue)}>
                <FormControlLabel value={PressureContainerValue.MotorCycle} control={<Radio color={"primary"}/>}
                                  label="Zulässige Motorschaltspiele/h [1/h]:"/>
                <TextField id={"pressure-container-netPressure"} value={pressureContainerValues.motorCycle}
                           disabled={selected === PressureContainerValue.MotorCycle}
                           onChange={event => handleOnChange(event, "motorCycle")} type={"number"}/>
                <FormControlLabel value={PressureContainerValue.Volume} control={<Radio color={"primary"}/>}
                                  label="Volumen des Druckluftbehälters [l]:"/>
                <TextField id={"pressure-container-diameter"} value={pressureContainerValues.volume}
                           disabled={selected === PressureContainerValue.Volume}
                           onChange={event => handleOnChange(event, "volume")} type={"number"}/>
            </RadioGroup>
        </CalcWrapper>
    );
};

export default PressureContainer