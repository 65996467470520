import {FC, useState} from "react";
import {round} from "../../../utils/helper";
import {handleUnit} from "./UnitHelper";
import FormLabelTextField from "../../../controls/FormLabelTextField";

enum WorkType {
    Kw,
    Ps
}

interface WorkUnitProps {
}
const MULTIPLIER = [1, 1.35962];

const WorkUnit : FC<WorkUnitProps> = () => {
    const [kw, setKw] = useState<string>("");
    const [ps, setPs] = useState<string>("");

    const setUnit = (value: number, type: WorkType) => {
        let currentMultiplier = MULTIPLIER[type];
        if (type !== 0)
            setKw(round(value / currentMultiplier, 3).toString());
        if (type !== 1)
            setPs(round((value / currentMultiplier) * MULTIPLIER[1], 3).toString());
    };

    return (
        <>
            <FormLabelTextField value={kw} label={"KW"}
                       onChange={event => handleUnit(event.target.value, setKw, WorkType.Kw, setUnit)}/>
            <FormLabelTextField value={ps} label={"PS"}
                       onChange={event => handleUnit(event.target.value, setPs, WorkType.Ps, setUnit)}/>
        </>
    );
};

export default WorkUnit