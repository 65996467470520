import {ChangeEvent, FC} from "react";
import {FormControl, MenuItem, Select} from "@material-ui/core";

interface PipeVolumeUnitSelectProps {
    value: VolumeUnit;
    setValue: (value: VolumeUnit) => void;
}

export enum VolumeUnit {
    MeterMinute = "m³/m",
    MeterHour = "m³/h"
}

const PipeVolumeUnitSelect : FC<PipeVolumeUnitSelectProps> = ({value, setValue}) => {

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as VolumeUnit);
    }

    return (
        <FormControl>
            <Select labelId={"pipe-volume-unit-select-label"} id={"pipe-volume-unit-select"} onChange={handleChange}
                    value={value}>
                <MenuItem value={VolumeUnit.MeterMinute}>{VolumeUnit.MeterMinute}</MenuItem>
                <MenuItem value={VolumeUnit.MeterHour}>{VolumeUnit.MeterHour}</MenuItem>
            </Select>
        </FormControl>
    );
};

export default PipeVolumeUnitSelect