import {ChangeEvent, FC, useEffect, useState} from "react";
import {
    calcLeakageResult,
    defaultLeakageResult,
    defaultLeakageValues,
    LeakageCalcValue,
    LeakageResult,
    LeakageValues,
    LeakageValuesKeys
} from "./LeakageHelper";
import LeakageMediumSelect, {Medium, MediumValue} from "./LeakageMediumSelect";
import {FormControlLabel, Radio, RadioGroup, TextField} from "@material-ui/core";
import FormLabelTextField from "../../../controls/FormLabelTextField";
import CalcWrapper from "../../../controls/CalcWrapper";
import {CalcProps} from "../../../types/types";

interface LeakageProps extends CalcProps{
}

const Leakage: FC<LeakageProps> = ({expanded}) => {
    const [selected, setSelected] = useState<LeakageCalcValue>(LeakageCalcValue.Drilling);
    const [leakageValues, setLeakageValues] = useState<LeakageValues>(defaultLeakageValues);
    const [mediumValue, setMediumValue] = useState<MediumValue>({medium: Medium.Luft, kappa: 287.1, gas: 1.4});
    const [leakageResult, setLeakageResult] = useState<LeakageResult>(defaultLeakageResult);
    const [drillResult, setDrillResult] = useState<LeakageResult>(defaultLeakageResult);

    const handleValues = (values: LeakageValues) => {
        const {leakageResult, drillResult} = calcLeakageResult(values, selected);
        setLeakageValues(values);
        setLeakageResult(leakageResult);
        setDrillResult(drillResult);
    };

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: LeakageValuesKeys) => {
        const {value} = event.target;
        handleValues({...leakageValues, [key]: value});
    }

    const onMediumChange = (mediumValue: MediumValue) => {
        setMediumValue(mediumValue);
        const {gas, kappa} = mediumValue;
        handleValues({...leakageValues, gas, kappa});
    };

    useEffect(() => {
        console.log(expanded);
        if(!expanded) reset();
    },[expanded])

    const reset = () => {
        setSelected(LeakageCalcValue.Drilling);
        setLeakageValues(defaultLeakageValues)
        setMediumValue({medium: Medium.Luft, kappa: 287.1, gas: 1.4})
        setLeakageResult(defaultLeakageResult)
        setDrillResult(defaultLeakageResult)
    }

    return (
        <CalcWrapper>
            <LeakageMediumSelect value={mediumValue} setValue={onMediumChange}/>
            <FormLabelTextField id={"leakage-pressureP1"} value={leakageValues.pressureP1} label={"Druck p1 [bar abs]:"}
                                onChange={event => handleOnChange(event, "pressureP1")}/>
            <FormLabelTextField id={"leakage-pressureP2"} value={leakageValues.pressureP2} label={"Druck p2 [bar abs]:"}
                                error={leakageValues.pressureP1 <= leakageValues.pressureP2 && leakageValues.pressureP2 !== ""}
                                helperText={"P2 muss kleiner als P1 sein"}
                                onChange={event => handleOnChange(event, "pressureP2")}/>
            <FormLabelTextField id={"leakage-temp"} value={leakageValues.temp} label={"Temperatur [Grad C]:"}
                                onChange={event => handleOnChange(event, "temp")}/>
            <RadioGroup value={selected} onChange={(e, value) => setSelected(value as LeakageCalcValue)}>
                <FormControlLabel value={LeakageCalcValue.LeakageFlow} control={<Radio color={"primary"}/>}
                                  label="Leckagestrom [l/min]:"/>
                {selected === LeakageCalcValue.LeakageFlow ?
                    <>
                        <FormLabelTextField id={"leakage-leakage-result-max"} value={leakageResult.max} disabled={true}
                                            label={"Max:"}/>
                        <FormLabelTextField id={"leakage-leakage-result-min"} value={leakageResult.min} disabled={true}
                                            label={"Min:"}/>
                    </> :
                    <TextField id={"leakage-leakage-flow"} value={leakageValues.leakage}
                               onChange={event => handleOnChange(event, "leakage")} type={"number"}/>}
                <FormControlLabel value={LeakageCalcValue.Drilling} control={<Radio color={"primary"}/>}
                                  label="Bohrungsdurchmesser [mm]:"/>
                {selected === LeakageCalcValue.Drilling ?
                    <>
                        <FormLabelTextField id={"leakage-drilling-result-max"} value={drillResult.max} disabled={true}
                                            label={"Max:"}/>
                        <FormLabelTextField id={"leakage-drilling-result-min"} value={drillResult.min} disabled={true}
                                            label={"Min:"}/>
                    </> :
                    <TextField id={"leakage-drill"} value={leakageValues.drill}
                               onChange={event => handleOnChange(event, "drill")} type={"number"}/>}
            </RadioGroup>
        </CalcWrapper>
    );
};

export default Leakage