import {ContainerLeakageValues} from "../../../types/types";
import {allStringSet, round} from "../../../utils/helper";

export const containerLeakageCalculator = (volume: number, startPressure: number, endPressure: number, measureTime: number) => {
    const mVolume = volume / 1000;
    const measureSeconds = measureTime / 60;
    return round((mVolume * (startPressure - endPressure)) / (measureSeconds), 2);
};

export const calcLeakageValues = (values: ContainerLeakageValues) => {
    const {volume, startPressure, endPressure, measureTime} = values;
    console.log(allStringSet(volume, startPressure, endPressure, measureTime))
    if (allStringSet(volume, startPressure, endPressure, measureTime))
        return containerLeakageCalculator(parseFloat(volume), parseFloat(startPressure), parseFloat(endPressure), parseFloat(measureTime))
    return;
};

export const defaultLeakageValue: ContainerLeakageValues = {
    volume: "",
    startPressure: "",
    endPressure: "",
    measureTime: ""
};