import {FC} from "react";
import {Box, FormControlLabel, makeStyles, TextField} from "@material-ui/core";
import {StandardTextFieldProps} from "@material-ui/core/TextField/TextField";

interface FormLabelTextFieldProps extends StandardTextFieldProps {
    boxClassName?: string;
}

const useStyles = makeStyles({
    label: {
        margin: 0
    },
    box: {
        width: "100%",
        display: "flex",
        flexDirection: "column"
    }
})

const useStyledTextField = makeStyles({
    disabled:{
        color: "red",
        backgroundColor: "#ddd"
    }
},{name: "MuiInput"})

const FormLabelTextField: FC<FormLabelTextFieldProps> = ({boxClassName = "", label, ...props}) => {
    const classes = useStyles();
    const textClasses = useStyledTextField();
    return (
        <Box className={classes.box + boxClassName}>
            <FormControlLabel label={label} control={<Box/>} className={classes.label}/>
            <TextField {...props} className={(props.className ?? "") + textClasses} type={"number"}/>
        </Box>
    );
};

export default FormLabelTextField