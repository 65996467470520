import {allStringSet, round} from "../../../utils/helper";

export const defaultLeakageValues: LeakageValues = {
    pressureP1: '',
    pressureP2: '',
    temp: '',
    kappa: 1.4,
    gas: 287.1,
    leakage: '',
    drill: '',
};

export const defaultLeakageResult: LeakageResult = {min: "", max: ""};

export type LeakageValues = {
    pressureP1: string;
    pressureP2: string;
    temp: string;
    kappa: number,
    gas: number,
    leakage: string,
    drill: string,
};

export type LeakageResult = {
    min: string;
    max: string;
};

export type LeakageValuesKeys = keyof LeakageValues;

export enum LeakageCalcValue {
    LeakageFlow = "LeakageFlow",
    Drilling = "Drilling"
}

export const leakageCalculator = (pressureP1: number, pressureP2: number, temp: number, kappa: number, gas: number, drill: number, leakage: number, selected: LeakageCalcValue) => {
    const calcTemp = 273 + temp;
    const pressure = pressureP2 / pressureP1;
    const kappa1 = (kappa - 1) / kappa;
    const kappa2 = kappa / (kappa - 1);
    const pressureA = Math.pow(pressure, (1 / kappa));
    const pressureB = Math.pow(pressure, kappa1);
    const x = Math.pow(2 / (kappa + 1), kappa2);
    const xA = Math.pow(x, (1 / kappa));
    const density = pressureP1 * 100000 / gas / calcTemp;
    let psi;
    if (pressure >= x) {
        psi = Math.pow(kappa2 * Math.pow(pressureA, 2) * (1 - pressureB), 0.5);
    } else {
        psi = Math.pow(kappa2 * (Math.pow(xA, 2) * (1 - xA)), 0.5);
    }
    switch (selected) {
        case LeakageCalcValue.LeakageFlow:
            const vv = Math.PI * Math.pow(drill / 1000, 2) / 4 * psi * Math.pow(2 * pressureP1 * 100000 * density, 0.5) / density;
            const maximal = vv * 60 * 1000;
            return {min: round(maximal, 2).toString(), max: round((maximal * 0.59), 2).toString()}
        case  LeakageCalcValue.Drilling:
            const calcLeakage = leakage / 60000;
            const area = calcLeakage * density / psi / Math.pow(2 * pressureP1 * 100000 * density, 0.5);
            const minimal = Math.pow(4 * (area / Math.PI), 0.5) * 1000;
            return {min: round((minimal / Math.pow(0.59, 0.5)), 2).toString(), max: round(minimal, 2).toString()}
    }
};

export const calcLeakageResult = (values: LeakageValues, selected: LeakageCalcValue) => {
    const {pressureP1, pressureP2, temp, gas, kappa, drill, leakage} = values;
    let leakageResult = {min: "", max: ""};
    let drillResult = {min: "", max: ""};
    switch (selected) {
        case LeakageCalcValue.LeakageFlow:
            if (allStringSet(pressureP1, pressureP2, temp, drill))
                leakageResult = leakageCalculator(parseFloat(pressureP1), parseFloat(pressureP2), parseFloat(temp), kappa, gas, parseFloat(drill), parseFloat(leakage), selected);
            break;
        case LeakageCalcValue.Drilling:
            if (allStringSet(pressureP1, pressureP2, temp, leakage))
                drillResult = leakageCalculator(parseFloat(pressureP1), parseFloat(pressureP2), parseFloat(temp), kappa, gas, parseFloat(drill), parseFloat(leakage), selected);
            break;
    }
    return {leakageResult, drillResult};
};