import {ChangeEvent, FC, useEffect, useState} from "react";
import {Box, FormControlLabel, makeStyles, Radio, RadioGroup, TextField} from "@material-ui/core";
import {calcArea, calcFlowValues, defaultFlowValues, FlowCalcValue, FlowValueKey, FlowValues} from "./FlowHelper";
import FlowVelocitySelect from "./FlowVelocitySelect";
import FlowVolumeSelect from "./FlowVolumeSelect";
import {round} from "../../../utils/helper";
import FormLabelTextField from "../../../controls/FormLabelTextField";
import CalcWrapper from "../../../controls/CalcWrapper";
import {CalcProps} from "../../../types/types";

interface FlowProps extends CalcProps{
}

const useStyles = makeStyles({
    dimensionBox: {
        display: "flex",
        width: "100%"
    },
    dimensions: {
        display: "flex",
        flexDirection: "column",
        width: "50%"
    }
});

const Flow: FC<FlowProps> = ({expanded}) => {
    const [selected, setSelected] = useState<FlowCalcValue>(FlowCalcValue.Area);
    const [flowValues, setFlowValues] = useState<FlowValues>(defaultFlowValues);
    const [selectedVolume, setSelectedVolume] = useState<string>("0");
    const [selectedVelocity, setSelectedVelocity] = useState<string>("0");
    const classes = useStyles();

    const handleValues = (values: FlowValues, key?: FlowValueKey) => {
        const {flowValues, selectedVolume} = calcFlowValues(values, selected);
        let resultValues = {...flowValues};
        const {area} = resultValues;
        if (area !== "" && key !== "length" && key !== "width") {
            const rootArea = round(Math.pow(parseFloat(area), 0.5), 2).toString();
            resultValues = {...resultValues, length: rootArea, width: rootArea}
        }
        if (area !== "" && key !== "diameter") {
            const rootArea = round(Math.pow(parseFloat(area) / Math.PI, 0.5) * 2, 2).toString();
            resultValues = {...resultValues, diameter: rootArea}
        }
        setFlowValues(resultValues);
        if (selectedVolume) setSelectedVolume(selectedVolume);
    };

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: FlowValueKey) => {
        const {value} = event.target;
        const values = {...flowValues, [key]: value};
        const areaValues = calcArea(values, key);
        handleValues(areaValues, key);
    }

    const handleVolumeSelect = (volume: string) => {
        setSelectedVolume(volume);
        handleValues({...flowValues, volume});
    }

    const handleVelocitySelect = (velocity: string) => {
        setSelectedVelocity(velocity);
        handleValues({...flowValues, velocity});
    }

    useEffect(() => {
        if(!expanded) reset();
    },[expanded])

    const reset = () => {
        setFlowValues(defaultFlowValues);
        setSelectedVolume("0")
        setSelectedVelocity("0");
        setSelected(FlowCalcValue.Area);
    }

    return (
        <CalcWrapper>
            <RadioGroup value={selected} onChange={(e, value) => setSelected(value as FlowCalcValue)}>
                <FormControlLabel value={FlowCalcValue.Volume} control={<Radio color={"primary"}/>}
                                  label="Kühlluftmenge [m³/h]:"/>
                <FlowVolumeSelect value={selectedVolume} setValue={handleVolumeSelect}
                                  disabled={selected === FlowCalcValue.Volume}/>
                <TextField id={"flow-volume"} value={flowValues.volume}
                           disabled={selected === FlowCalcValue.Volume} type={"number"}
                           onChange={event => handleOnChange(event, "volume")}/>
                <FormControlLabel value={FlowCalcValue.Velocity} control={<Radio color={"primary"}/>}
                                  label="Empfohlene Geschwindigkeit [m/s]:"/>
                <FlowVelocitySelect value={selectedVelocity} setValue={handleVelocitySelect}
                                    disabled={selected === FlowCalcValue.Velocity}/>
                <TextField id={"flow-velocity"} value={flowValues.velocity}
                           disabled={selected === FlowCalcValue.Velocity} type={"number"}
                           onChange={event => handleOnChange(event, "velocity")}/>
                <FormControlLabel value={FlowCalcValue.Area} control={<Radio color={"primary"}/>}
                                  label="Freie Fläche [m²]:"/>
                <TextField id={"flow-area"} value={flowValues.area}
                           disabled={selected === FlowCalcValue.Area} type={"number"}
                           onChange={event => handleOnChange(event, "area")}/>
                <Box className={classes.dimensionBox}>
                    <FormLabelTextField id={"flow-length"} value={flowValues.length} label={"Länge [m]:"}
                                        disabled={selected === FlowCalcValue.Area} className={classes.dimensions}
                                        onChange={event => handleOnChange(event, "length")}/>
                    <FormLabelTextField id={"flow-width"} value={flowValues.width} label={"Breite [m]:"}
                                        disabled={selected === FlowCalcValue.Area} className={classes.dimensions}
                                        onChange={event => handleOnChange(event, "width")}/>
                </Box>
                <FormLabelTextField id={"flow-width"} value={flowValues.diameter} label={"Durchmesser [m]:"}
                                    disabled={selected === FlowCalcValue.Area}
                                    onChange={event => handleOnChange(event, "diameter")}/>
            </RadioGroup>
        </CalcWrapper>
    );
};

export default Flow