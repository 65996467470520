import {ChangeEvent, FC, useEffect, useState} from "react";
import {
    defaultIncreasedWorkValues,
    increasedWorkCalculator,
    IncreasedWorkValues,
    IncreasedWorkValuesKey
} from "./IncreasedWorkHelper";
import {allStringSet} from "../../../utils/helper";
import FormLabelTextField from "../../../controls/FormLabelTextField";
import CalcWrapper from "../../../controls/CalcWrapper";
import {CalcProps} from "../../../types/types";

interface IncreasedWorkProps extends CalcProps {
}

const IncreasedWork: FC<IncreasedWorkProps> = ({expanded}) => {
    const [increasedWorkValues, setIncreasedWorkValues] = useState<IncreasedWorkValues>(defaultIncreasedWorkValues);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: IncreasedWorkValuesKey) => {
        const {value} = event.target;
        let fieldValue = parseFloat(value);
        if (key === "temp") {
            if (fieldValue > 50) fieldValue = 50;
            if (fieldValue < 0) fieldValue = 0;
        }
        const newValues = {...increasedWorkValues, [key]: fieldValue.toString()};
        const {pressureP1, temp} = newValues;
        let resultValues = {...newValues};
        if (allStringSet(pressureP1, temp)) {
            const result = increasedWorkCalculator(parseFloat(pressureP1), parseFloat(temp));
            resultValues.increasedWork = (Math.round((result - 1) * Math.pow(10, 3)) / 10).toString();
        }
        setIncreasedWorkValues(resultValues);
    };

    useEffect(() => {
        if(!expanded) reset();
    },[expanded])

    const reset = () => {
        setIncreasedWorkValues(defaultIncreasedWorkValues);
    }

    return (
        <CalcWrapper>
            <FormLabelTextField id={"increased-work-pressureP1"} value={increasedWorkValues.pressureP1}
                                label={"Netzdruck [bar]:"}
                                onChange={event => handleOnChange(event, "pressureP1")}/>
            <FormLabelTextField id={"increased-work-temp"} value={increasedWorkValues.temp}
                                label={"Umgebungstemperatur [°C]:"}
                                onChange={event => handleOnChange(event, "temp")}/>
            <FormLabelTextField id={"increased-work-increasedWork"} value={increasedWorkValues.increasedWork}
                                label={"Leistungsaufnahme [%]:"} disabled={true}/>
        </CalcWrapper>
    );
};

export default IncreasedWork