import {Box, FormControl, FormControlLabel, MenuItem, Select} from "@material-ui/core";
import {ChangeEvent, FC} from "react";

export enum Unit {
    Druck = "Druck",
    Volumen = "Volumen",
    Leistung = "Leistung",
    Temperatur = "Temperatur",
    Volumenstrom = "Volumenstrom"
}

interface UnitSelectProps {
    value: Unit;
    setValue: (value: Unit) => void;
}

const units = [Unit.Druck, Unit.Volumen, Unit.Leistung, Unit.Temperatur, Unit.Volumenstrom];

const UnitSelect: FC<UnitSelectProps> = ({value, setValue}) => {

    const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as Unit);
    }

    return (
        <FormControl>
            <FormControlLabel control={<Box/>} label={"Einheit"} style={{margin: 0}}/>
            <Select labelId={"leakage-medium-select-label"} id={"leakage-medium-select"} onChange={handleChange}
                    value={value}>
                {units.map(unit => <MenuItem value={unit} key={unit}>{unit}</MenuItem>)}
            </Select>
        </FormControl>
    );
};

export default UnitSelect